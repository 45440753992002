import {TableContainer, Table, TableHead, TableRow, TextField, TableCell, Button, Paper, Checkbox, InputAdornment, TableBody} from "@mui/material";
import React, {useContext, useEffect, useRef, useState} from "react";
import SupplierRow from "../SupplierRow/SupplierRow";

const TableSupplier = ({handleOpenModalSupplier, suppliers, ...props}) => {
  const styleCell = {
    lineHeight: "1em",
    textAlign: "center",
    whiteWrap: "nowrap",
  };

  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" sx={{overflowY: "visible"}}>
      <Table sx={{minWidth: 650}} aria-label="simple table">
        <TableHead
          style={{
            backgroundColor: "#f8f7f7",
          }}>
          <TableRow>
            <TableCell>
              <Button variant="contained" color="primary" onClick={handleOpenModalSupplier}>
                + ADICIONAR FORNECEDOR
              </Button>
            </TableCell>

            <TableCell style={styleCell} sx={{minWidth: 150}}>
              Entregável
            </TableCell>
            <TableCell style={styleCell}>Carta</TableCell>
            <TableCell style={styleCell}>Bi-Tributação</TableCell>
            <TableCell style={styleCell} sx={{minWidth: 100}}>
              Valor do <br /> fornecedor
            </TableCell>
            <TableCell style={styleCell} sx={{minWidth: 80}}>
              Markup %
            </TableCell>
            <TableCell style={styleCell}>Total</TableCell>
          </TableRow>
        </TableHead>
        <TableBody>
          {suppliers.map((supplier, i) => (
            <SupplierRow key={supplier.resource_uuid} supplier={supplier} {...props} />
          ))}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default TableSupplier;
