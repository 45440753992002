import React from "react";
import {TextField, MenuItem} from "@mui/material";

const SelectInput = ({options, ...props}) => {
  return (
    <TextField select {...props}>
      {options.map(option => (
        <MenuItem key={option.uuid} value={option.uuid}>
          {option.name}
        </MenuItem>
      ))}
    </TextField>
  );
};

export default SelectInput;
