import React from "react";
import {TableContainer, Table, TableHead, TableRow, TableCell, Paper, TableBody} from "@mui/material";
import {table} from "../../../methods/methods";

const DetailTableSuppliers = ({project}) => {
  const styleCell = {
    lineHeight: "1em",
    textAlign: "center",
    whiteWrap: "nowrap",
  };
  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" sx={{overflowY: "visible"}}>
      <Table sx={{minWidth: 650, overflowY: "visible"}} aria-label="simple table">
        <TableHead
          style={{
            backgroundColor: "#f8f7f7",
          }}>
          <TableRow>
            <TableCell style={styleCell}>Fornecedor</TableCell>
            <TableCell style={styleCell}>Entregável</TableCell>
            <TableCell style={styleCell}>Bi-tributação</TableCell>
            <TableCell style={styleCell}>Valor do fornecedor</TableCell>
            <TableCell style={styleCell}>Markup (%)</TableCell>
            <TableCell style={styleCell}>Markup (R$)</TableCell>
            <TableCell style={styleCell}>Impostos</TableCell>
            <TableCell style={styleCell}>Total</TableCell>
          </TableRow>
        </TableHead>

        <TableBody>
          {project.project_resources.map(resource => {
            if (resource.resource_kind == 2) {
              return (
                <TableRow style={styleCell} key={resource.resource_uuid}>
                  <TableCell style={styleCell}>{resource.resource_supplier}</TableCell>
                  <TableCell style={styleCell}>{resource.resource_name}</TableCell>
                  <TableCell style={styleCell}>{table.numberToReais(resource.resource_double_taxation_value)}</TableCell>
                  <TableCell style={styleCell}>{resource.resource_cost || 0}</TableCell>
                  <TableCell style={styleCell}>{resource.resource_supplier_markup * 100 + "%"}</TableCell>
                  <TableCell style={styleCell}>{table.numberToReais(resource.resource_supplier_markup_value)}</TableCell>
                  <TableCell style={styleCell}>{table.numberToReais(resource.resource_tax_value)}</TableCell>
                  <TableCell style={styleCell}>{table.numberToReais(resource.resource_total)}</TableCell>
                </TableRow>
              );
            }
          })}
        </TableBody>
      </Table>
    </TableContainer>
  );
};

export default DetailTableSuppliers;
