import {useEffect, useState, useContext, useRef} from "react";
import {Modal, TextField, InputAdornment, Button, FormControlLabel, Checkbox} from "@mui/material";
import {TitleModal, BoxContainer, ContainerButtons} from "../../Budget/components/ModalCenario/style";
import CloseIcon from "@mui/icons-material/Close";
import DeleteOutlineIcon from "@mui/icons-material/DeleteOutline";
import {WebSocketContext} from "../../Budget/service/WSContext";
import DialogConfirmDelete from "../../Budget/components/DialogConfirmDelete/DialogConfirmDelete";
import SelectInput from "../../Budget/components/SelectInput/SelectInput";

const ModalAcessos = ({open, onClose, current, modalTitle}) => {
  const [openDialog, setOpenDialog] = useState(false);
  const [message, sendMessageWS] = useContext(WebSocketContext);

  const accessGroupType = group => {
    if (group === "False") {
      return false;
    } else {
      return true;
    }
  };

  const accessData = useRef({
    user_email: current ? current.user_email : "",
    access_group: current ? accessGroupType(current.group) : false,
  });

  // useEffect(() => {
  //   if (open) {
  //     sendMessageWS({method: "list_areas"});
  //   }
  // }, [open]);

  const tipo = [
    {
      uuid: 0,
      access_group: true,
      name: "Financeiro",
    },
    {
      uuid: 1,
      access_group: false,
      name: "Padrão",
    },
  ];

  const areas = [
    {
      uuid: 1,
      name: "FCB",
    },
    {
      uuid: 1,
      name: "IPG",
    },
  ];

  useEffect(() => {
    if (current) {
      accessData.current.user_email = current.user_email;
      accessData.current.access_group = accessGroupType(current.group);
    }
  }, []);

  const handleInput = e => {
    if (e.target.name === "access_group") {
      if (e.target.value === 0) {
        accessData.current[e.target.name] = true;
      }
      if (e.target.value === 1) {
        accessData.current[e.target.name] = false;
      }
    } else {
      accessData.current[e.target.name] = e.target.value;
    }
  };

  const handleUpdateAccess = () => {
    if (current) {
      sendMessageWS({
        method: "update_access",
        data: {...accessData.current, access_id: current.id},
      });
    } else {
      sendMessageWS({method: "update_access", data: accessData.current});
    }
    onClose();
  };

  const handleDeleteAccess = () => {
    sendMessageWS({
      method: "update_access",
      data: {...accessData.current, access_id: current.id, delete_access: true},
    });
    onClose();
  };

  return (
    <Modal open={open} onClose={onClose}>
      <BoxContainer>
        <TitleModal>
          {modalTitle} <CloseIcon onClick={onClose} />
        </TitleModal>

        <TextField
          name="user_email"
          placeholder="E-mail"
          variant="outlined"
          label="E-mail"
          defaultValue={current && current.user_email}
          onChange={handleInput}
        />

        <ContainerButtons>
          <SelectInput
            name="access_group"
            onChange={handleInput}
            sx={{flexGrow: 1}}
            variant="outlined"
            label="Tipo de acesso"
            options={tipo}
            defaultValue={current && current.group === "True" ? 0 : 1}
          />
        </ContainerButtons>
        {/* <SelectInput
          name="area_uuid"
          onChange={handleInput}
          id="outlined-basic"
          sx={{flexGrow: 1}}
          variant="outlined"
          label="Área"
          options={grupo}
          defaultValue={current && current.area__uuid}
        /> */}

        <ContainerButtons>
          {current && (
            <Button
              onClick={() => {
                setOpenDialog(true);
              }}
              variant="contained"
              color="error">
              <DeleteOutlineIcon />
            </Button>
          )}
          <Button
            className="main-btn"
            onClick={() => {
              handleUpdateAccess();
            }}
            variant="contained">
            {current ? "Salvar" : "Adicionar"}
          </Button>
        </ContainerButtons>

        <DialogConfirmDelete subject={"esse acesso"} open={openDialog} handleDelete={handleDeleteAccess} setDialogDeleteOpen={setOpenDialog} />
      </BoxContainer>
    </Modal>
  );
};

export default ModalAcessos;
