import styled from "styled-components";
import colors from "../../../utils/colors.json";

const StatusAprovacao = styled.div`
  border: 1px solid ${colors["outline-border"]};
  border-radius: 5px;
  padding: 5px 10px;
  text-transform: uppercase;
  font-size: 0.7rem;
  font-weight: 700;
  color: #2e2e2eab;
  width: fit-content;
  margin: 5px 0;
  margin-right: auto;

  ${props =>
    props.variant == "nb" &&
    `
    background: ${colors.nb};
    color: #2e2e2e86;
`}
  ${props =>
    props.variant == "3" &&
    `
    background: ${colors.aprovado};
`}

${props =>
    props.variant == "2" &&
    `
    background: ${colors.aguardando};
`}

${props =>
    props.variant == "1" &&
    `
    background: ${colors.criado};
`}
`;

export {StatusAprovacao};
