import {Table, TableHead, TableContainer, Paper, TableRow, TableCell, TableBody} from "@mui/material";
import React, {useRef, useState} from "react";
import {useNavigate} from "react-router-dom";
import EditIcon from "@mui/icons-material/Edit";
import ModalAcessos from "../ModalAcessos/ModalAcessos";

const TableAcessos = ({accessList}) => {
  const navigate = useNavigate();
  const [openModalAccess, setOpenModalAccess] = useState(false);
  const selectedAccess = useRef();

  return (
    <TableContainer component={Paper} elevation={0} variant="outlined" sx={{bgcolor: "transparent", marginBottom: 1, boxSizing: "border-box"}}>
      <Table sx={{minWidth: 650}} aria-label="simple table" stickyHeader>
        <TableHead>
          <TableRow>
            <TableCell>E-mail</TableCell>
            <TableCell>Tipo de acesso</TableCell>
            {/* <TableCell>Área</TableCell> */}
            <TableCell></TableCell>
          </TableRow>
        </TableHead>

        <TableBody style={{position: "relative"}} align="left">
          {accessList.map(access => {
            return (
              <TableRow
                id={access.id}
                hover
                sx={{
                  "&:last-child td, &:last-child th": {border: 0},
                  "bgcolor": "#ffffffbc",
                }}>
                <TableCell id={access.id} style={{width: "fit-content"}}>{access.user_email}</TableCell>
                <TableCell  id={access.id} style={{width: "fit-content"}}>{access.group === "True" ? "Financeiro" : "Padrão"}</TableCell>
                {/* <TableCell style={{width: "fit-content"}}>FCB</TableCell> */}
                <TableCell
                  id={access.id}
                  onClick={() => {
                    setOpenModalAccess(true);
                    selectedAccess.current = access;
                  }}
                  style={{width: "fit-content", cursor: "pointer", textAlign: "right"}}>
                  <EditIcon id={access.id} />
                </TableCell>
              </TableRow>
            );
          })}
        </TableBody>
      </Table>

      {openModalAccess && (
        <ModalAcessos
          open={openModalAccess}
          onClose={() => {
            setOpenModalAccess(false);
          }}
          modalTitle={"Editar acesso"}
          current={selectedAccess.current}
        />
      )}
    </TableContainer>
  );
};

export default TableAcessos;
