import React, {useEffect, useState, useContext} from "react";
import {ContainerListaCargos} from "./style";
import HeaderGeral from "../../../components/HeaderGeral/HeaderGeral";
import fte from "../../../utils/cargos.json";
import TableRecursos from "../../components/TableRecursos/TableRecursos";
import {Button} from "@mui/material";
import ModalCreateRole from "../../components/ModalCreateRole/ModalCreateRole";
import {modal} from "../../../methods/methods";
import {WebSocketContext} from "../../service/WSContext";
import makeRequest from "../../../utils/makeRequest";
import getCsrfToken from "../../../utils/getCsrfToken";
import getCookie from "../../../utils/GetCookie";

const ListaCargos = () => {
  var columns = ["Nome", "Area", "Salário", ""];
  // var columns = ["Nome", "Area", "Tipo", "Salário", "TBH", ""];
  const [file, setFile] = useState(null);
  const [openModal, setOpenModal] = useState(false);
  const [roles, setRoles] = useState([]);
  const [filtered, setFiltered] = useState([]);
  const [message, sendMessageWS] = useContext(WebSocketContext);

  useEffect(() => {
    document.title = `BUDGET | Clientes`;
    sendMessageWS({method: "list_roles", data: {agency_uuid: getCookie("agencyuuid") && getCookie("agencyuuid")}});
  }, []);

  useEffect(() => {
    if (message && message.method == "list_roles" && message.data !== null) {
      setRoles(message.data.roles);
    }
    if (message && message.method == "update_role" && message.data !== null) {
      setRoles(message.data.roles);
    }
  }, [message]);

  useEffect(() => {
    setFiltered(roles);
  }, [roles]);

  const handleSendFile = () => {
    var url = `${process.env.REACT_APP_HTTP_SERVER_URL}/agency/upload_role/`;

    let formData = new FormData();
    formData.append("role_file", file);
    formData.append("agency_uuid", getCookie("agencyuuid"));
    // formData.append("agency_uuid", window.sessionStorage.getItem("ag-id") && window.sessionStorage.getItem("ag-id"));

    getCsrfToken().then(res => {
      makeRequest(url, formData, "post", "", res.data).then(response => {
        if (response.status == 200) {
          sendMessageWS({method: "list_roles"});
          setOpenModal(false);
        }
      });
    });
  };

  var originalData = [...roles];

  const handleSearch = e => {
    var searchString = e.target.value.toLowerCase();
    const itemsFiltered = originalData.filter(b => {
      if (searchString === "") {
        return b;
      } else if (b.name.toLowerCase().includes(searchString) || b.area__name.toLowerCase().includes(searchString)) {
        return b;
      }
    });
    setFiltered(itemsFiltered);
  };

  return (
    <ContainerListaCargos>
      <HeaderGeral
        file={file}
        setFile={setFile}
        handleSendFile={handleSendFile}
        titulo={""}
        subtitulo={"Cargos"}
        btnCta={"Novo Recurso"}
        placeholder={"Pesquise uma posição..."}
        handleClickCta={e => {
          modal.open(setOpenModal);
        }}
        planilhaCta={true}
        agency={false}
        year={false}
        search={true}
        budget={true}
        handleSearch={handleSearch}
      />
      <TableRecursos columns={columns} rows={filtered} listdata={filtered} />

      <ModalCreateRole
        open={openModal}
        onClose={() => {
          modal.close(setOpenModal);
        }}
        modalTitle={"Novo Cargo"}
      />
    </ContainerListaCargos>
  );
};

export default ListaCargos;
